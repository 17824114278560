.button {
  box-shadow: 0px 3px 5px rgba(23, 24, 26, 0.03);
}

.loading_indicator {
  transition: all 0.2s;
  position: relative;
}
.loading_indicator::after {
  content: '';
  position: absolute;
  width: 24px;
  height: 24px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid #797c82;
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}
@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}
