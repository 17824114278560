.search {
  @apply relative;
}

.search:before {
  content: '';
  @apply h-full border-l border-lightgrey transform rotate-12 absolute left-0 top-0;
}

.search:after {
  content: '';
  @apply h-full border-l border-lightgrey transform rotate-12 absolute right-0 top-0;
}
