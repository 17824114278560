.fade-enter {
  opacity: 0;
  transform: translateY(-20%);
}
.fade-exit {
  opacity: 1;
  transform: translateY(0);
}
.fade-enter-active {
  opacity: 1;
  transform: translateY(0);
}
.fade-exit-active {
  opacity: 0;
  transform: translateY(-20%);
}
.fade-enter-active,
.fade-exit-active {
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}
