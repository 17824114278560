.raw-html {
  margin-top: 20px;
  margin-bottom: 60px;
  min-height: 500px;
  color: #555c5f;
}

.raw-html p {
  margin-top: 10px;
  display: block;
}

.raw-html ul,
.raw-html ol {
  padding: 0px 0px 0px 30px;
  margin: 10px 0px;
}

.raw-html ul li,
.raw-html ol li {
  margin-top: 5px;
}

.raw-html ul strong > li,
.raw-html ol strong > li {
  font-weight: 600;
}

.raw-html strong {
  font-weight: 600;
}

.raw-html a {
  color: #0083a0;
}

.raw-html hr {
  margin: 50px 0;
}

.raw-html ol {
  padding-left: 1.25rem;
  list-style: decimal;
}
.raw-html ol[type^='a'] {
  list-style: lower-alpha;
}
.raw-html ol[type^='i'] {
  list-style: lower-roman;
}

.raw-html .pp-table {
  border: solid 1px #333539;
  border-spacing: 0;
}
.raw-html .pp-table tr {
  border-spacing: 0;
}
.raw-html .pp-table td {
  border: solid 1px #333539;
  padding: 3px;
}
.raw-html .pp-table .center td {
  text-align: center;
}
@media (max-width: 640px) {
  .raw-html .pp-table {
    max-width: 100%;
    width: fit-content;
    overflow: auto;
    display: flex;
    box-shadow: -3px 0px 5px 0px #d0d0d0 inset;
    left: 0;
  }
}
.raw-html .link-to {
  color: #00c3ee;
  font-size: 1.125rem;
}
.raw-html h3 {
  font-size: 20px;
  color: #292c2f;
  font-weight: 600;
}
