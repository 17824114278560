.promotionBanner {
  position: relative;
  background-color: rgb(255, 230, 207);
  /* min-height: var(--promotion-banner-height); */
  /* transition: top var(--promotion-banner-animation-duration) ease-in-out; */
  /* top: calc(var(--promotion-banner-height) * -1); */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 30;
}

.bannerText {
  text-align: center;
}
.bannerText strong {
  font-weight: 600;
}
.bannerText p {
  color: #007285;
  font-size: 0.78rem;
}
@media (min-width: 768px) {
  .bannerText p {
    font-size: 1.0625rem;
  }
}

.bannerActions {
  position: absolute;
  height: 100%;
  right: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.bannerCloseAction {
  @apply pb-px rounded-full text-center;
  background-color: rgb(14, 114, 133);
  color: #fff;
  width: 20px;
  height: 20px;
  line-height: 19px;
}
