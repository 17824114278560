.modal-centered-enter {
  opacity: 0;
}

.modal-centered-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}

.modal-centered-exit {
  opacity: 1;
}

.modal-centered-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}

.modal-centered-inner-enter {
  transform: scale(0);
}

.modal-centered-inner-enter-active {
  transform: scale(1);
  transition: transform 200ms;
}

.modal-centered-inner-exit {
  transform: scale(1);
}

.modal-centered-inner-exit-active {
  transform: scale(0);
  transition: transform 200ms;
}
