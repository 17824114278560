@supports (-webkit-touch-callout: none) and (not (translate: none)) {
  .search-form-dropdown-container > div:not(:last-child) {
    margin-bottom: 0.625rem;
  }
}
@media (min-width: 768px) {
  @supports (-webkit-touch-callout: none) and (not (translate: none)) {
    .search-form-dropdown-container > div:not(:last-child) {
      margin-right: 0.625rem;
      margin-bottom: 0;
    }
  }
}

@supports (-webkit-touch-callout: none) {
  .cta-ios-fix {
    margin-bottom: env(safe-area-inset-bottom);
    transition: margin-bottom 0.2s;
  }
}
