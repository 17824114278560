@font-face {
  font-family: 'proxima-nova';
  src: url('https://use.typekit.net/af/949f99/00000000000000003b9b3068/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/949f99/00000000000000003b9b3068/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('woff'),
    url('https://use.typekit.net/af/949f99/00000000000000003b9b3068/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('opentype');
  font-display: swap;
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: 'proxima-nova';
  src: url('https://use.typekit.net/af/576d53/00000000000000003b9b3066/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/576d53/00000000000000003b9b3066/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3')
      format('woff'),
    url('https://use.typekit.net/af/576d53/00000000000000003b9b3066/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3')
      format('opentype');
  font-display: swap;
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: 'proxima-nova';
  src: url('https://use.typekit.net/af/705e94/00000000000000003b9b3062/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/705e94/00000000000000003b9b3062/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff'),
    url('https://use.typekit.net/af/705e94/00000000000000003b9b3062/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('opentype');
  font-display: swap;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'proxima-nova';
  src: url('https://use.typekit.net/af/cebe0e/00000000000000003b9b3060/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/cebe0e/00000000000000003b9b3060/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
      format('woff'),
    url('https://use.typekit.net/af/cebe0e/00000000000000003b9b3060/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
      format('opentype');
  font-display: swap;
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'proxima-nova';
  src: url('https://use.typekit.net/af/6e816b/00000000000000003b9b3064/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/6e816b/00000000000000003b9b3064/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3')
      format('woff'),
    url('https://use.typekit.net/af/6e816b/00000000000000003b9b3064/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3')
      format('opentype');
  font-display: swap;
  font-style: normal;
  font-weight: 500;
}

/* icon font */

@font-face {
  font-family: 'gwat';
  src: url('/fonts/gwat.eot');
  src: url('/fonts/gwat.eot#iefix') format('embedded-opentype'),
    url('/fonts/gwat.woff2') format('woff2'),
    url('/fonts/gwat.ttf') format('truetype'),
    url('/fonts/gwat.woff') format('woff'),
    url('/fonts/gwat.svg#gwat') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'gwat' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-tik-tok:before {
  content: '\e93b';
}
.icon-donate:before {
  content: '\e90f';
  color: #565c5f;
}
.icon-phone:before {
  content: '\e931';
}
.icon-search:before {
  content: '\e934';
}
.icon-bell:before {
  content: '\e914';
}
.icon-alert:before {
  content: '\e914';
}
.icon-notification:before {
  content: '\e914';
}
.icon-user:before {
  content: '\e932';
}
.icon-profile:before {
  content: '\e932';
}
.icon-account:before {
  content: '\e932';
}
.icon-edit:before {
  content: '\e926';
}
.icon-manage:before {
  content: '\e926';
}
.icon-calendar:before {
  content: '\e91d';
}
.icon-location:before {
  content: '\e92e';
}
.icon-age:before {
  content: '\e913';
}
.icon-duration:before {
  content: '\e925';
}
.icon-list:before {
  content: '\e92d';
}
.icon-cards:before {
  content: '\e92d';
}
.icon-filters:before {
  content: '\e929';
}
.icon-funnel:before {
  content: '\e92b';
}
.icon-open-in-new-window:before {
  content: '\e930';
}
.icon-share:before {
  content: '\e938';
}
.icon-upload:before {
  content: '\e938';
}
.icon-download:before {
  content: '\e924';
}
.icon-warning:before {
  content: '\e90e';
}
.icon-thumb-down:before {
  content: '\e936';
}
.icon-thumb-up:before {
  content: '\e937';
}
.icon-lightbulb:before {
  content: '\e92c';
}
.icon-fire:before {
  content: '\e92a';
}
.icon-deal:before {
  content: '\e923';
}
.icon-dollar:before {
  content: '\e923';
}
.icon-money:before {
  content: '\e923';
}
.icon-cloud:before {
  content: '\e909';
}
.icon-heart-outline:before {
  content: '\e927';
}
.icon-favourite-outline:before {
  content: '\e927';
}
.icon-like-outline:before {
  content: '\e927';
}
.icon-wishlist-outline:before {
  content: '\e927';
}
.icon-heart-full:before {
  content: '\e928';
}
.icon-favourite:before {
  content: '\e928';
}
.icon-like:before {
  content: '\e928';
}
.icon-wishlist:before {
  content: '\e928';
}
.icon-star-outline:before {
  content: '\e935';
}
.icon-star-full:before {
  content: '\e90c';
}
.icon-mail-outline:before {
  content: '\e908';
}
.icon-mail:before {
  content: '\e907';
}
.icon-checkbox-blank:before {
  content: '\e91f';
}
.icon-checkbox:before {
  content: '\e920';
}
.icon-check:before {
  content: '\e91e';
}
.icon-question:before {
  content: '\e90a';
}
.icon-add:before {
  content: '\e912';
}
.icon-expand:before {
  content: '\e912';
}
.icon-minus:before {
  content: '\e92f';
}
.icon-collapse:before {
  content: '\e92f';
}
.icon-close:before {
  content: '\e921';
}
.icon-remove:before {
  content: '\e933';
}
.icon-clear:before {
  content: '\e933';
}
.icon-arrow-up:before {
  content: '\e91b';
}
.icon-arrow-right:before {
  content: '\e919';
}
.icon-arrow-down:before {
  content: '\e915';
}
.icon-arrow-left:before {
  content: '\e917';
}
.icon-up:before {
  content: '\e91c';
}
.icon-right:before {
  content: '\e91a';
}
.icon-down:before {
  content: '\e916';
}
.icon-left:before {
  content: '\e918';
}
.icon-facebook:before {
  content: '\e904';
}
.icon-facebook1:before {
  content: '\e905';
}
.icon-messenger:before {
  content: '\e903';
}
.icon-instagram:before {
  content: '\e906';
}
.icon-twitter:before {
  content: '\e90b';
}
.icon-whatsapp:before {
  content: '\e90d';
  color: #33d06b;
}
.icon-linkedin:before {
  content: '\e900';
}
.icon-youtube:before {
  content: '\e901';
}
.icon-pinterest:before {
  content: '\e902';
}
.icon-add_circle_outline:before {
  content: '\e939';
}
.icon-remove_circle_outline:before {
  content: '\e93a';
}
.icon-access_time:before {
  content: '\e922';
}
.icon-navigate_next:before {
  content: '\e910';
}
.icon-info_outline:before {
  content: '\e911';
}
.icon-external-link:before {
  content: '\f08e';
}
