.inner-html-content p {
  margin: 0.625rem 0;
}

.more-bottom-margin {
  margin-bottom: 1.25rem !important;
}

.inner-html-content string {
  font-size: 600;
}

.inner-html-content ul {
  padding-left: 1.25rem;
  list-style: initial;
}

.inner-html-content li {
  padding-bottom: 0.625rem;
}

.inner-html-content h2 {
  font-size: 1.625rem;
  line-height: 2rem;
  font-weight: 700;
  margin-top: 1.562rem;
  margin-bottom: 0.312rem;
}

.inner-html-content h3 {
  margin-top: 1.562rem;
  margin-bottom: 0.312rem;
  font-size: 1.25rem;
  font-weight: 600;
  color: #292c2f;
}

.inner-html-content h3 + p {
  margin-top: 0;
}

.inner-html-content .trip-jobs-grid {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

@media (min-width: 640px) {
  .inner-html-content .trip-jobs-grid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.inner-html-content .list-col-2 {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

/* Target the second instance of list-col-2 */
.inner-html-content .list-col-2:nth-of-type(2) {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

@media (min-width: 640px) {
  .inner-html-content .list-col-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.inner-html-content .list-col-3 {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

@media (min-width: 640px) {
  .inner-html-content .list-col-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.inner-html-content #visa-link {
  cursor: pointer;
  color: #0083a0;
}

.inner-html-content a {
  color: #0083a0;
}

.inner-html-content .disclaimer {
  font-size: 0.75rem;
}

.trip-visa-details {
  padding: 1.5rem 0;
  color: #17181a;
}

.trip-visa-details h1 {
  margin-bottom: 0.625rem;
  font-size: 1.625rem;
  line-height: 2rem;
  font-weight: 700;
  text-align: left;
}

.trip-visa-details h2 {
  margin-top: 1.5rem;
  margin-bottom: 0.3rem;
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: 600;
  text-align: left;
}

.trip-visa-details ul {
  padding-left: 1.25rem;
  margin: 1rem 0;
  text-align: left;
}

.trip-visa-details li {
  padding-bottom: 0.625rem;
  font-size: 1rem;
  line-height: 1.25rem;
  list-style: disc;
}

.trip-visa-details p {
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: left;
}

.trip-visa-details a {
  color: #0083a0;
}

.coronavirus-page-container p {
  font-size: 1.125rem;
  line-height: 1.5rem;
  margin-bottom: 1.25rem !important;
  color: rgba(87, 91, 99, var(--tw-text-opacity));
}

.coronavirus-page-container h2 {
  font-size: 1.125rem;
  color: rgb(87, 91, 99);
  margin-top: 0rem;
}

.inclusion-description-mobile * {
  @apply !text-base !leading-1.313rem;
}
