.active {
  transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out !important;
  transform: translateY(0.0625rem) !important;
  opacity: 1 !important;
  pointer-events: auto !important;
}

.gworldMenu .gworldDropdown .gworldDropdownMenu {
  transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out;
  transform-origin: top;
  transform: translateY(-3.125rem);
  opacity: 0;
  pointer-events: none;
}
