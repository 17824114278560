.sideNav {
  transform: translateX(100vw);
  transition: transform 0.25s ease-in-out,
    height var(--promotion-banner-animation-duration) ease-in-out,
    top var(--promotion-banner-animation-duration) ease-in-out;
}

.sideNav.active {
  transform: translateX(0);
}
